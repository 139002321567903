import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import SanityLink from '~components/SanityLink'
import { css } from '@emotion/react'

const serializers = {
	marks: {
		link: props => <SanityLink link={props.mark} css={css`text-decoration: underline;`}>{props.children}</SanityLink>
	},
	types: {
		block: props => {
			const {style = 'normal'} = props.node 
			if (props.children[0] === '') {
				return <br/>
			}
			return BlockContent.defaultSerializers.types.block(props)
		},
	}
}

const RichText = ({ content }) => <BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>


RichText.propTypes = {
	content: PropTypes.array,
}

export default RichText
