import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import MailchimpForm from '~components/MailchimpForm'
import RichText from '~components/RichText'
import SanityLink from '~components/SanityLink'
import { Star } from '~components/Svg'
import { mobile, tablet } from '~styles/global'

const Footer = ({ className }) => {

	const {
		mailingListTitle,
		namePlaceholder,
		emailPlaceholder,
		footerStatement,
		infoMenuTitle,
		infoMenu,
		socialMenuTitle,
		socialMenu,
		successMessage
	} = useSiteSettings()

	return(
		<Wrap className={className}>
			<Section>
				<MailingList>
					<MLTitleBlock>
						<MLTitle>{mailingListTitle}</MLTitle>
					</MLTitleBlock>
					<MailchimpForm namePlaceholder={namePlaceholder} emailPlaceholder={emailPlaceholder} successMessage={successMessage}/>
				</MailingList>
				<FooterStatement>
					<RichText content={footerStatement}/>	
				</FooterStatement>
				<StarCol>
					<StarIcon />
				</StarCol>
				<InfoLinks>
					<FooterTitle>
						{infoMenuTitle}
					</FooterTitle>
					{infoMenu.map(item => (
						<MenuLink className='h6' key={item.text} link={item.link}>{item.text}</MenuLink>
					))}
				</InfoLinks>
				<SocialLinks>
					<FooterTitle>
						{socialMenuTitle}
					</FooterTitle>
					{socialMenu.map(item => (
						<MenuLink className='h6' key={item.text} link={item.link}>{item.text}</MenuLink>
					))}
				</SocialLinks>
				<Credits className='med'>
					<div>© Midnight Mixers {new Date().getFullYear()}</div>
					<div>Design &amp; build by <a href="https://formwork.build" target="_blank" rel="noreferrer">Formwork</a></div>
				</Credits>
			</Section>
		</Wrap>
	)
}

const Wrap = styled.footer`
	button{
		grid-column: span 12;
		text-align: left;
	}
`
const MailingList = styled.div`
	grid-column: span 12;
	border-top: 1px solid var(--white);
	border-bottom: 1px solid var(--white);
	display: flex;
	margin-bottom: var(--xl);
	${mobile}{
		flex-wrap: wrap;
		padding: var(--xxl) 0;
	}
`
const MLTitleBlock = styled.div`
	border-right: 1px solid var(--white);
	width: 35%;
	display: flex;
	align-items: center;
	${tablet}{
		padding-right: var(--s);
	}
	${mobile}{
		width: 100%;
		border-right: none;
		justify-content: center;
		padding-right: 0;
	}
`
const MLTitle = styled.h6`
	${mobile}{
		margin-bottom: var(--s);	
	}
`
const FooterStatement = styled.div`
	grid-column: 1/5;
	font-size: 29px;
	${tablet}{
		grid-column: 1/7;
	}
	${mobile}{
		margin-bottom: var(--l);
		grid-column: span 12;
	}
`
const InfoLinks = styled.div`
	grid-column: 8/10;
	${mobile}{
		grid-column: span 6;
		margin-bottom: var(--l);
	}
`
const FooterTitle = styled.h6`
	margin-bottom: var(--s);
`
const SocialLinks = styled.div`
	grid-column: 10/12;
	${mobile}{
		grid-column: span 6;
	}
`
const MenuLink = styled(SanityLink)`
	display: block;
`
const Credits = styled.div`
	border-top: 1px solid var(--white);
	grid-column: span 12;
	margin-top: var(--xl);
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 62px;
	margin-bottom: var(--xs);
	${mobile}{
		justify-content: flex-start;
		flex-wrap: wrap;
		height: auto;	
		padding: var(--s) 0;
		> div {
			width: 100%;
			padding-bottom: var(--xxs)
		}
	}
`
const StarCol = styled.div`
	grid-column: 6/8;
	text-align: center;
	align-self: center;
	${tablet}{
		display: none;
	}
`
const StarIcon = styled(Star)`
	width: 38px;
`

Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
