const resolveLink = (doc) => {
	if(!doc || (doc._type === 'productWithVariant' && !doc?.product)) return null
	const type = doc._type
	const slug = doc.slug?.current

	switch (type) {
	case 'productWithVariant':
		return `/products/${doc?.product?.store?.slug?.current}${doc?.variant?.store?.id ? `/?v=${doc?.variant?.store?.id}` : ''}`
	case 'product':
		return `/products/${doc?.store?.slug?.current}`
	case 'recipe':
		return `/recipes/${slug}`
	case 'page':
		return `/${slug}`
	case 'homePage':
		return '/'
	default:
		return `/${slug}`
	}
}

export default resolveLink
