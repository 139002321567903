import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { motion } from 'framer-motion'
import SanityLink from '~components/SanityLink'
import Section from '~components/Section'
import resolveLink from '~utils/resolveLink'

const MobileMenu = ({ className, open, menu }) => {

	return (
		<Wrap className={className} open={open}>
			<Section>
				<Container>
					{menu.map((item, i) => (
						<MenuLinkRow key={item._key}>
							<motion.div 
								initial="hidden"
								animate={open ? 'visible' : 'hidden'}
								variants={{
									visible: { 
										opacity: 1,	
										transition: {
											delay: (i + 1) * 0.1,
											duration: 0.6
										} 
									},
									hidden: { opacity: 0}
								}}
							>
								<MenuLink 
									className='h3' 
									link={item.link} 
									activeClassName='active'
								>
									{item.text}
								</MenuLink>
							</motion.div>
						</MenuLinkRow>
					))}
				</Container>
			</Section>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--darkBlue);
	z-index: 99;
	opacity: ${ props => props.open ? '1' : '0'};
	transition: opacity 0.25s;
	pointer-events: ${ props => props.open ? 'all' : 'none'};
	padding-top: 93px;
`
const Container = styled.div`
	grid-column: span 12;
`
const MenuLinkRow = styled.div`
	margin-bottom: 8px;
`
const MenuLink = styled(SanityLink)`
	
`

MobileMenu.propTypes = {
	className: PropTypes.string,
	open: PropTypes.boolean,
	menu: PropTypes.array,
}

export default MobileMenu