import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
				navigation {
					...button
				}
				mailingListTitle
				namePlaceholder
				emailPlaceholder
				successMessage
				footerStatement: _rawFooterStatement
				infoMenuTitle
				infoMenu {
					...button
				}
				socialMenuTitle
				socialMenu{
					...button
				}
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
      }
    }
    `)

	return siteSettings?.sanitySiteSettings
}

export default useSiteSettings
