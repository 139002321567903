module.exports = {
	siteUrl: 'https://midnightmixers.gatsbyjs.io',
	buildsUrl: 'https://midnightmixers.gtsb.io',
	previewUrl: 'https://preview-midnightmixers.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/e006ca5d-f980-41d5-bf8a-4afb2897e18c',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	gTag: 'G-0RKZ17HTPM'
}
