import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import sendToMailchimp from '../utils/sendToMailchimp'
import { LongRarr } from '~components/Svg'
import { mobile, tablet } from '~styles/global'
import Button from '~components/Button'

const NewsletterSignupForm = ({ className, successMessage }) => {
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		sendToMailchimp(values.email, values.fname, values.lname, (err, data) => {
			if(err){
				console.log(err, data)
				setErrors([{
					field: null,
					message: 'Sorry we can\'t sign you up right now.'
				}])
			}
			else{
				setSuccess(true)
			}
		})
	}

	const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<Success css={css`
        opacity: ${success ? '1' : '0'};
        pointer-events: ${success ? 'all' : 'none'};
      `}>
				{successMessage}
			</Success>
			<FormWrap css={css`
        opacity: ${success ? '0' : '1'};
        pointer-events: ${success ? 'none' : 'all'};
        `}>
				<Form onSubmit={e => validate(e)} noValidate>
					<Inputs>
						<Input
							className='med'
							type="text"
							name="name"
							placeholder="Your name"
							onChange={e => setValues({...values, fname: e.target.value})}
						/>
						<Email>
							<Input
								className='med'
								type="email"
								name="email"
								placeholder="Your email address"
								onChange={e => setValues({...values, email: e.target.value})}
							/>
							{errors?.length > 0 &&
								<Errors className='h7'>
									{errors.map(error => <p>{error.message}</p>)}
								</Errors>
							}
						</Email>
					</Inputs>
					<SubmitBlock>
						<SubmitDesk type="submit" className='h6'>
            	Go
							<RightArrow />
						</SubmitDesk>
						<SubmitMob type="submit" className='h6'>
							Go
							<RightArrow />
						</SubmitMob>
					</SubmitBlock>
				</Form>
			</FormWrap>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
	flex: 1;
`
const FormWrap = styled.div`
  position: relative;
  transition: opacity 0.3s;
`
const Form = styled.form`
	height: 95px;
	display: flex;
	align-items: center;
	${mobile}{
		flex-direction: column;
		height: unset;
	}	
`
const Errors = styled.div`
	margin-top: 4px;
	position: absolute;
`
const Success = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s 0.3s;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	padding-left: 50px;
	${tablet}{
		padding-left: 40px;
	}
	${mobile}{
		justify-content: center;
		padding-left: 0;
	}
`
const Inputs = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	padding-left: 50px;
	${tablet}{
		padding-left: 40px;
	}
	${mobile}{
		padding-left: 0;
		flex-direction: column;
	}
`
const Input = styled.input`
	background-color: transparent;
	border: none;
	font-family: inherit;
	color: var(--white);
	border-bottom: 1px solid var(--white);
	margin-right: 40px;
	height: 35px;
	min-width: 236px;
	position: relative;
	top: -4px;
	::placeholder{
		opacity: 1;
		color: var(--white);
	}
	:-webkit-autofill,
	:-webkit-autofill:hover, 
	:-webkit-autofill:focus{
		-webkit-text-fill-color: var(--white);
	}
	${tablet}{
		min-width: 186px;
	}
	${mobile}{
		margin-bottom: var(--s);
		margin-right: 0;
		min-width: 246px;
	}
`
const Email = styled.div`
	position: relative;
`
const SubmitBlock = styled.div`
	border-left: 1px solid;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 125px;
	${mobile}{
		border-left: none;
		width: 100%;
	}
`
const SubmitDesk = styled.button`
	${mobile}{
		display: none;
	}
`
const SubmitMob = styled.button`
	display: none;
	${mobile}{
		display: block;
		border: 1px solid;
		border-radius: 106px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 52px;
		padding: 0 var(--m);
		margin-top: var(--l);
	}
`
const RightArrow = styled(LongRarr)`
	width: 16px;
	margin-left: 17px;
	${mobile}{
		margin-left: 10px;
	}
`

NewsletterSignupForm.propTypes = {
	successMessage: PropTypes.string,
}

export default NewsletterSignupForm
