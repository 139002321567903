import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import SanityLink from '~components/SanityLink'
import Section from '~components/Section'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
  body {
    font-family: 'Masqualero', serif;
		font-size: 26px;
		line-height: 1.25;
		letter-spacing: -0.17px;
		background: linear-gradient(180deg, #00091B 0%, #001744 100%);
		color: var(--white);
		${tablet}{
			font-size: 22px;
		}
  }

  :root{
    --white: #ffffff;
    --black: #000000;
		--darkBlue: #000C22;
		--midBlue: #001744;
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px;
    }
  }

  p{
		margin-bottom: 0.78em;
    &:last-child{
      margin-bottom: 0;
    }
  }

  h1,
  .h1 {
    font-size: 70px;
    line-height: 1.25;
    ${tablet} {
      font-size: 50px;
    }
  }

	h2,
  .h2 {
    font-size: 60px;
    line-height: 1.08;
    ${tablet} {
      font-size: 40px;
    }
  }

	h3,
  .h3 {
    font-size: 46px;
    line-height: 1.2;
		font-style: italic;
    ${tablet} {
      font-size: 33px;
    }
  }

	h4,
  .h4 {
    font-size: 19px;
    line-height: 1.2;
		font-family: 'Halcom';
		text-transform: uppercase;
		letter-spacing: 2.83333px;
    ${tablet} {
      font-size: 15px;
    }
  }

	h5,
  .h5 {
    font-size: 22px;
    line-height: 1.2;
		font-style: italic;
    ${tablet} {
      font-size: 20px;
    }
  }

	h6,
  .h6 {
    font-size: 14px;
    line-height: 1.6;
		font-family: 'Halcom';
		text-transform: uppercase;
		letter-spacing: 2.83333px;
    ${tablet} {
      font-size: 11px;
    }
  }

	.med {
		font-size: 18px;
		line-height: 1.2;
		${tablet}{
			font-size: 15px;
		}
	}
	
	.med-ital{
		font-size: 18px;
		line-height: 1.2;
		font-style: italic;
		${tablet}{
			font-size: 15px;
		}
	}

  .h7 {
    font-size: 13px;
    line-height: 1.2;
		font-style: italic;
  }

	.small{
		font-size: 21px;
	}
	
	.xs{
		font-size: 12px;
    line-height: 1.6;
		font-family: 'Halcom';
		text-transform: uppercase;
		${mobile}{
			font-size: 10px;
		}
	}

	.ReactCollapse--collapse {
		transition: height 0.6s cubic-bezier(0.5, 1, 0.89, 1);
	}
`

export const UnderlineButton = styled(SanityLink)`
	position: relative;
	:after{
		content: '';
		height: 1px;
		position: absolute;
		bottom: -0.6em;
		left: 0;
		right: 0;
		background-color: var(--white);
	}
`
export const PageHeader = styled(Section)`
	margin-top: calc(170px + var(--xxxl));
	text-align: center;
	margin-bottom: var(--xxl);
	${mobile}{
		margin-top: calc(95px + var(--xxxl));
	}
`
export const Headline = styled.h6`
	grid-column: span 12;
	margin-bottom: var(--m);
	text-align: center;
`
export const Subtitle = styled.h3`
	grid-column: span 12;
	max-width: 430px;
	margin: 0 auto;
`

const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

	input, select {
		border-radius: 0;
		-webkit-appearance: none;
	}

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
		border-bottom: 1px solid var(--white);
		font-family: inherit;
	}

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }

`

export default GlobalStyles
