exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stylesheet-js": () => import("./../../../src/pages/stylesheet.js" /* webpackChunkName: "component---src-pages-stylesheet-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/ProductsPage.js" /* webpackChunkName: "component---src-templates-products-page-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/Recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-recipes-page-js": () => import("./../../../src/templates/RecipesPage.js" /* webpackChunkName: "component---src-templates-recipes-page-js" */),
  "component---src-templates-stockists-page-js": () => import("./../../../src/templates/StockistsPage.js" /* webpackChunkName: "component---src-templates-stockists-page-js" */),
  "component---src-templates-sustainability-page-js": () => import("./../../../src/templates/SustainabilityPage.js" /* webpackChunkName: "component---src-templates-sustainability-page-js" */)
}

