import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'

const BorderButton = ({ className, link, children, onClick, sm, type }) => {

	const [hover, setHover] = useState(false)

	return (
		<Wrap 
			className={className + ' h6'} 
			link={link} 
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={onClick}
			hover={hover}
			sm={sm}
			type={type}
		>
			{link && 
				<LinkOverlay link={link}/>
			}
			{children}
		</Wrap>
	)
}

const Wrap = styled.button`
	border: 1px solid;
	border-radius: 106px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: ${ props => props.sm ? '197px' : '203px'};
	min-width: ${ props => props.sm ? '197px' : '203px'};
	min-height: ${ props => props.sm ? '52px' : '65px'};
	position: relative;
`
const LinkOverlay = styled(SanityLink)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`

BorderButton.propTypes = {
	className: PropTypes.string,
	link: PropTypes.object,
	children: PropTypes.node,
	onClick: PropTypes.string,
	sm: PropTypes.string,
	reverse: PropTypes.bool,
	type: PropTypes.string,
}

export default BorderButton