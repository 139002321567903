import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { PlusIcon, MinusIcon, PlusSmall, MinusSmall } from '~components/Svg'

const Incrementer = ({ className, onChange, initialValue, maxValue, small }) => {
  
	const [value, setValue] = useState(0)

	useEffect(() => {
		setValue(initialValue ?? 1)
	}, [initialValue])

	const updateValue = newValue => {
		if(newValue > 0 && (!maxValue || newValue <= maxValue)){
			setValue(newValue)
			if(onChange){
				onChange(newValue)
			}
		}
		return
	}

	return (
		<Wrap className={className} small={small}>
			<Inc 
				onClick={() => updateValue(value - 1)}
				css={css`left: 0px;`}
			>
				{small ? 
					<StyledMinusSmall />
					:
					<StyledMinusIcon/>
				}
			</Inc>
			<Input 
				type="text" 
				readOnly={true} 
				className='h6'
				value={small ? value : value + ` Case${value > 1 ? 's' : ''}`}
			/>
			<Inc 
				onClick={() => updateValue(value + 1)}
				css={css`right: 0px;`}
			>
				{small ? 
					<StyledPlusSmall />
					:
					<StyledPlusIcon/>
				}
			</Inc>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
  width: ${ props => props.small ? '93px' : '181px'};
  height: ${ props => props.small ? '37px' : '65px'};
`

const Input = styled.input`
  -moz-appearnace: none;
  -webkit-appearance: none;
  border: none;
  height: 100%;
  text-align: center;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid;
  /* font-family: inherit; */
	background-color: transparent;
	color: var(--white);
	border-radius: 106px;
`

const Inc = styled.button`
  padding: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
	display: flex;
	align-items: center;
`
const StyledMinusIcon = styled(MinusIcon)`
	width: 19px;
	margin-left: 8px;
`
const StyledPlusIcon = styled(PlusIcon)`
	width: 19px;
	margin-right: 8px;
`
const StyledMinusSmall = styled(MinusSmall)`
	width: 10px;
	margin-left: 4px;
`
const StyledPlusSmall = styled(PlusSmall)`
	width: 11px;
	margin-right: 4px;
`
Incrementer.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func, 
	initialValue: PropTypes.number, 
	maxValue: PropTypes.number,
	small: PropTypes.bool,
}

export default Incrementer
